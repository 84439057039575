import { createContext } from "react"

const attachDispatch = (socket, target) => {
    socket.onmessage = (socketMessage) => {
        const message = JSON.parse(socketMessage.data)
        target.dispatchEvent(new CustomEvent(message.type, {
            detail: message.data
        }))
    }
}

function socketManager (socket) {
    const target = new EventTarget()

    attachDispatch(socket, target)

    target.addEventListener('~wsupdate', (d) => {
        attachDispatch(d.detail.socket, target)
    })

    return target
}

const Socket = createContext(false)

export default Socket
export { socketManager }
