import React from "react";
import useAxios from "axios-hooks";
import {formatDate, parseFusionResponse, url} from "../../../helpers";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SimpleTable from "../../SimpleTable";
import StatusTag from "../../../core/Tags";
import "./style.scss";
import {Button} from "rt-design-system-backup";

import {useHistory} from "react-router-dom/cjs/react-router-dom.min";

const STPAPIConsoleDetails = ({action = "details"}) => {
    const history = useHistory();

    const {apiId} = useParams();
    const [{data, loading}] = useAxios({
        url: url(`/trade_sync/${apiId}/`),
    }, {
        useCache: false,
    });


    if (loading) {
        return <>Loading...</>;
    }


    return (
        <div className="stp-details-container">
            {action === "details" ? (
                <Breadcrumb text="STP API Console" link={"/stp-api-console"}/>
            ) : null}

            <div className="stp-api-main">
                <h1 className="stp-api">STP API Console Details</h1>

                <Button
                    icon={"edit"}
                    large
                    outlined
                    onClick={() => {
                        history.push(`/stp-api-console/update/${apiId}`);
                    }}
                    disabled={["Waiting", "In progress"].includes(data.status)}
                >
                    Edit STP API
                </Button>
            </div>

            <hr/>

            <div className="details-content">
                <h4 className="details-title">STP API Console #{data.id}</h4>

                <div className="details-table-container">
                    <SimpleTable className="details-table">
                        <tr>
                            <td>System ID:</td>
                            <td>{data.system_id ?? "-"}</td>
                        </tr>
                        <tr>
                            <td>Request ID:</td>
                            <td>{data.request_id ?? "-"}</td>
                        </tr>
                        <tr>
                            <td>System:</td>
                            <td>{data.system}</td>
                        </tr>
                        <tr>
                            <td>Status:</td>
                            <td>
                                <StatusTag status={data.status}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Created on:</td>
                            <td>{formatDate(data.created_at)}</td>
                        </tr>
                        <tr>
                            <td>Updated at:</td>
                            <td>{formatDate(data.updated_at)}</td>
                        </tr>
                        <tr>
                            <td>Response Code:</td>
                            <td>{data.response_code ?? "-"}</td>
                        </tr>
                        <tr>
                            <td>Response Message:</td>
                            <td>{data.response_message ?? "-"}</td>
                        </tr>
                        <tr>
                            <td>Error:</td>
                            <td>{data.error ?? "-"}</td>
                        </tr>
                        <tr>
                            <td>Retry Count:</td>
                            <td>{data.retry_count ?? "-"}</td>
                        </tr>
                        <tr>
                            <td>Retry At:</td>
                            <td>{data.retry_at ?? "-"}</td>
                        </tr>
                        <tr>
                            <td>Action:</td>
                            <td>{data.action}</td>
                        </tr>
                    </SimpleTable>
                </div>
                <div className="table-main">
                    <table className="table">
                        <thead>
                        <tr>
                            <th className="table-header">NexTrade Payload</th>
                            <th className="table-header">Fusion Response</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="table-bottom">
                  <pre>
                    {JSON.stringify(
                        data?.payload ? JSON.parse(data.payload) : {},
                        null,
                        2
                    )}
                  </pre>
                            </td>
                            <td className="table-bottom">
                                <pre>{parseFusionResponse(data?.fusion_response)}</pre>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
export default STPAPIConsoleDetails;
