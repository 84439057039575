import {css} from "@emotion/css";
import {Button, Table} from "rt-design-system-backup";
import {currency_symbol_mapping} from "../../RequestMaturityPrices/columns";
import React, {useState} from "react";
import {capitalize, formatNumber, isValidNumber} from "../../../helpers";
import TableInput from "../../TableInput";
import {InputGroup} from "../../Form";

const tableCSS = css({
  'thead > tr > th': {
    verticalAlign: 'top',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
  },
  'thead > tr > th:nth-child(5), thead > tr > th:nth-child(6)': {
    verticalAlign: 'top',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
    backgroundColor: '#b2b2b2',
  },
  'tbody > tr > td': {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
  },
  'tbody > tr > td:nth-child(5), tbody > tr > td:nth-child(6)': {
    fontSize: '14px',
    lineHeight: '22px',
    color: '#000000',
    backgroundColor: '#eaeaea',
  }
});

const AllocationTables = ({ request, allocationData, setAllocationData , setIsvalid, salesEntities}) => {
  const [numberTables, setNumberTables] = useState(2);
  const maturities = request.request_maturities.map((requestMaturity) => {
    let LMETrade = requestMaturity.trades.find((trade) => {
      return trade.instrument.name === "LME Aluminium";
    });

    if (!LMETrade) LMETrade = requestMaturity.trades[0];

    const OtherTrade = requestMaturity.trades.find((trade) => {
      return trade.instrument.name !== "LME Aluminium";
    });

    const prefill_mkp = ["Quote", "At Market"].includes(request.request_type)

    return {
      lme_trade_id: LMETrade.id,
      mkp_trade_id: OtherTrade && OtherTrade.id,
      maturity: requestMaturity.maturity.shortened_string,
      action: requestMaturity.trades[0].action,
      quantity: requestMaturity.trades[0].quantity,
      lme_price: LMETrade.price,
      lme_price_currency: LMETrade.price_currency,
      lme_qt_id: LMETrade.qt_id,
      mkp_price: OtherTrade && ((OtherTrade.price && parseFloat(OtherTrade.price).toFixed(2)) || (prefill_mkp && OtherTrade.compute_mw_premium ? parseFloat(OtherTrade.compute_mw_premium).toFixed(2) : '')),
      mkp_price_currency: OtherTrade && OtherTrade.price_currency && parseFloat(OtherTrade.price_currency).toFixed(2),
      mkp_qt_id: OtherTrade && OtherTrade.qt_id,
      total_price_usd: requestMaturity.total_price,
      total_price_currency: requestMaturity.total_price_currency,
      currency_forward_rate: LMETrade.forward_rate,
    };
  });

  const onTableInputChange = (record, tableId, fieldName, value) => {
    setAllocationData((prevState) => {
      if (!prevState[tableId]) {
        prevState[tableId] = {
          "trades": {}
        };
      }

      if(record.lme_trade_id && !prevState[tableId]["trades"][record.lme_trade_id]) {
        prevState[tableId]["trades"][record.lme_trade_id] = {}
      }
      if(record.mkp_trade_id && !prevState[tableId]["trades"][record.mkp_trade_id]) {
        prevState[tableId]["trades"][record.mkp_trade_id] = {}
      }

      if (fieldName === "quantity") {
        if (record.lme_trade_id) {
          prevState[tableId]["trades"][record.lme_trade_id][fieldName] = value;
        }
        if (record.mkp_trade_id) {
          prevState[tableId]["trades"][record.mkp_trade_id][fieldName] = value;
        }
      } else if (fieldName.startsWith("lme")) {
        prevState[tableId]["trades"][record.lme_trade_id][fieldName] = value
      } else if (fieldName.startsWith("mkp")) {
        prevState[tableId]["trades"][record.mkp_trade_id][fieldName] = value
      }

      return ({
        ...prevState
      })
    })
  };

  const onSalesEntityChange = (tableId, value) => {
    setAllocationData((prevState) => {
      if (prevState[tableId]) {
        prevState[tableId]["sales_entity"] = value[0].value;
      } else {
        prevState[tableId] = {
          "trades": {}
        };
        prevState[tableId]["sales_entity"] = value[0].value;
      }
      return ({
        ...prevState
      })
    })
  }

  const renderTableInput = (record, key, tableId, inputID, value, tabbable=true, enabled=true)=> {
    return (
        <TableInput
          required
          tabbable={tabbable}
          enabled={enabled}
          key={key}
          isInvalid={(str) => {
            const error = isValidNumber(str, true, true)
            if (error) {
              setIsvalid(false)
            }
            return error
          }}
          value={allocationData[tableId] && allocationData[tableId][inputID]}
          onChange={(x) => {
            onTableInputChange(record, tableId, inputID, x)
          }}
          id={inputID}
        />
    )
  };
  // {...allocationData, [tableId]: {...allocationData.tableId, [inputID]: value}}
  const getColumns = (id) => {
    const currency = request.currency;
    const isForeignCurrency = currency !== "USD";

    const isSpot = request.delivery_months === "Spot";

    const isLMEOnly = request.request_maturities[0].trades.length === 1;
    let allocation_columns = [
      {
        title: "Maturities",
        fixed: "left",
        dataIndex: "maturity",
        render: capitalize,
      },
      {
        title: "Buy/Sell",
        dataIndex: "action",
      },
      {
        title: (
          <>
            Quantity
            <br />
            (mt)
          </>
        ),
        dataIndex: "quantity",
        render: (value, record) => renderTableInput(record,`quantity_${id}`, id, 'quantity', value),
      },
      {
        title: (
          <>
            {"LME adjusted"}
            <br />({"$"}/mt)
          </>
        ),
        dataIndex: "lme_price",
        render: value => formatNumber(value, true, 2)
      },
    ]
    if (!isSpot) {
      const item = {
        title: (
          <>
            Fusion ID
            <br />
            {`(LME)`}
          </>
        ),
        dataIndex: "lme_qt_id",
        render: value => formatNumber(value, true, 2)
      }
      allocation_columns.push(item);
    }
    if (! isLMEOnly) {
      const item = {
        title: (
          <>
            Market premium
            <br />({"$/mt"})
          </>
        ),
        dataIndex: "mkp_price",
        render: value => value,
      }
      allocation_columns.push(item);
    }
    if (!isSpot && !isLMEOnly) {
      const item = {
        title: (
          <>
            Fusion ID
            <br />
            {`(MKP)`}
          </>
        ),
        dataIndex: "mkp_qt_id",
        render: value => formatNumber(value, true, 2)
      }
      allocation_columns.push(item);
    }
    if (isForeignCurrency) {
      const forward_rate_item = {
        title: (
          <>
            {currency}/USD
            <br />
            Forward
          </>
        ),
        dataIndex: "currency_forward_rate",
        render: value => formatNumber(value,true, 4),
      }
      allocation_columns.push(forward_rate_item);
      const lme_adjusted_item = {
        title: (
          <>
            {"LME adjusted"}
            <br />({currency_symbol_mapping[currency] || currency || "USD"}/mt)
          </>
        ),
        dataIndex: "lme_price_currency",
        render: value => formatNumber(value, true, 2)
      }
      allocation_columns.push(lme_adjusted_item);
      if (!isLMEOnly) {
        const mkp_item = {
        title: (
          <>
            Market premium
            <br />({currency_symbol_mapping[currency] || currency || "USD"}/mt)
          </>
        ),
        dataIndex: "mkp_price_currency",
        render: value => formatNumber(parseInt(value), true, 2)
      }
      allocation_columns.push(mkp_item);
      }
    }
    return allocation_columns;
  }

  return (
    <>
      <h4>Maturity prices</h4>
      <div style={{ position: "relative" }}>
        <div style={{ display: "inline-block", maxWidth: "100%" }}>
          {[...Array(numberTables).keys()].map((id, index) => {
            return (
                <>
                  {index > 0 ? <InputGroup
                    required
                    type="select"
                    title="Sales entity"
                    name="sales_entity"
                    style={{maxWidth: 'fit-content'}}
                    items={salesEntities.map((item) => {
                      return {
                        name: item.name,
                        value: item.id,
                        currency: item.currency
                      };
                    })}
                    onChange={(e) => {
                      onSalesEntityChange(id, e);
                    }}
                  /> : null}
                  <Table
                    pagination={false}
                    key={id}
                    dataSource={maturities}
                    className={tableCSS}
                    scroll={{
                      x: true,
                    }}
                    size="small"
                    columns={getColumns(id)}
                    style={{marginBottom: 20}}
                  />
                </>
            )
          })
          }
          <Button
            onClick={() => {setNumberTables(numberTables + 1)}}
            text="Add Sales Entity"
            large
            intent={"primary"}
            style={{marginTop: 10}}
          />
        </div>
      </div>
    </>
  );
};

export default AllocationTables;