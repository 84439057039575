import React, { useContext } from "react";
import SimpleTable from "../SimpleTable";
import TableInput from "../TableInput";

import RequestViewContext from "../RequestContext";

import { isValidNumber, formatNumber, renameInstrument } from "../../helpers";
import { currency_symbol_mapping } from "../RequestMaturityPrices/columns";

const EditableField = ({
  value,
  label,
  editable,
  suffix,
  onChange,
  tabbable,
  id,
  decimals,
}) => {
  return (
    <tr>
      <td>
        <b>{label}:</b>
      </td>
      <td>
        <TableInput
          required
          value={value}
          isInvalid={isValidNumber}
          enabled={editable}
          tabbable={tabbable}
          id={label}
          formatContents={(value) => {
            return value ? formatNumber(value, true, decimals) : "-";
          }}
          onChange={onChange}
        />
      </td>
    </tr>
  );
};

const RequestVWP = ({
  request,
  editable = true,
  embedded = false,
  lmeInput = true,
}) => {
  const currency = request.currency;
  const isForeignCurrency = request.currency !== "USD";
  const trades =
    request.request_maturities &&
    request.request_maturities[0] &&
    request.request_maturities[0].trades;
  const OtherTrade =
    trades &&
    trades
      .filter((trade) => trade.instrument.name !== "LME Aluminium")
      .map((trade) => trade)[0];
  const LMETrade =
    trades &&
    trades.filter((trade) => {
      return trade.instrument.name === "LME Aluminium";
    })[0];
  const lme_adjusted =
    LMETrade &&
    (request.currency !== "USD" ? LMETrade.price_currency : LMETrade.price);
  const { update } = useContext(RequestViewContext);

  const Tag = embedded ? ({ children }) => children : SimpleTable;

  const showAdjusted = ["Quote", "Auto Quote", "Indic Quote"].includes(
    request.request_type
  );
  const showLMEBasis =
    ["Quote", "Auto Quote", "Indic Quote", "CSP", "Limit Order"].indexOf(
      request.request_type
    ) >= 0;

  return !embedded &&
    request.request_type !== "Quote" &&
    request.request_type !== "Auto Quote" &&
    request.request_type !== "Indic Quote" ? null : (
    <>
      {embedded ? null : (
        <h4 style={{ marginTop: 20 }}>Volume Weighted Price</h4>
      )}
      <Tag noBold>
        {embedded || !showAdjusted ? null : (
          <tr>
            <td>
              <b>
                LME Adjusted ({currency_symbol_mapping[request.currency]}/mt):
              </b>
            </td>
            <td>
              {formatNumber(
                isForeignCurrency
                  ? request.vwap_lme_adjusted_currency
                  : request.vwap_lme_adjusted,
                true,
                true
              )}
            </td>
          </tr>
        )}
        {showLMEBasis && lmeInput ? (
          <EditableField
            editable={editable}
            onChange={(value) =>
              request.lme_basis !== value
                ? update({
                    lme_basis: value,
                  })
                : {}
            }
            decimals={2}
            tabbable
            label="LME Basis 3M ($/mt)"
            value={request.lme_basis}
          />
        ) : null}

        {showLMEBasis && lmeInput && request.request_type === "Indic Quote" ? (
          <tr>
            <td>
              <b>Spread ($/mt) = {formatNumber(request.lme_basis,true,2)} - {formatNumber(isForeignCurrency? request.vwap_lme_adjusted: request.vwap_lme_adjusted,true,2)}:</b>
            </td>
            <td>
              {formatNumber(request.lme_basis - (isForeignCurrency ? request.vwap_lme_adjusted : request.vwap_lme_adjusted),true,2)}
            </td>
          </tr>
        ) : null}
        {isForeignCurrency && request.request_type !== "At Market" ? (
          <EditableField
            onChange={(value) =>
              update({
                spot_exchange_rate: value,
              })
            }
            decimals={4}
            editable={editable}
            tabbable
            label={currency + "/USD Spot"}
            value={request.spot_exchange_rate}
          />
        ) : null}
        {showAdjusted && (isForeignCurrency || embedded) ? (
          <>
            {lmeInput ? (
              <EditableField
                label="LME Adjusted ($/t)"
                value={request.vwap_lme_adjusted}
                decimals={2}
              />
            ) : null}
            {isForeignCurrency && (
              <EditableField
                label={currency + "/USD Forward"}
                value={request.vwap_currency_forward}
                decimals={4}
              />
            )}
          </>
        ) : null}
        {["Auto Quote", "Indic Quote"].includes(request.request_type) &&
        OtherTrade ? (
          <tr>
            <td>
              <b>{renameInstrument(OtherTrade.instrument.name)} ($/mt)</b>
            </td>
            <td>{formatNumber(request.vwap_mkp, true, true)}</td>
          </tr>
        ) : null}
        {["Auto Quote", "Indic Quote"].includes(request.request_type) &&
        lme_adjusted &&
        OtherTrade &&
        OtherTrade.instrument.name === "MWP" ? (
          <tr>
            <td>
              <b>LME Adjusted (¢/lb):</b>
            </td>
            <td>{formatNumber(lme_adjusted / 22.0462, true, true)}</td>
          </tr>
        ) : null}
        {["Auto Quote", "Indic Quote"].includes(request.request_type) &&
        OtherTrade &&
        OtherTrade.instrument.unit.includes("lb") ? (
          <tr>
            <td>
              <b>
                {renameInstrument(OtherTrade.instrument.name)} (
                {OtherTrade.instrument.unit})
              </b>
            </td>
            <td>{formatNumber(request.vwap_mkp / 22.0462, true, true)}</td>
          </tr>
        ) : null}
      </Tag>
    </>
  );
};

export default RequestVWP;

/**
 * LME Adjusted (currency/t)
 * LME Basis 3M ($/t) - editable
 * Currency/USD Spot - editable
 * LME Adjusted ($/t)
 * Currency/USD Forward
 */
