import React, { useState } from 'react'
import { Table } from "rt-design-system-backup";
import { css } from '@emotion/css'
import columns from "./columns";
import { capitalize, formatNumber, url } from "../../helpers";
import useAxios from 'axios-hooks';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

const Trades = ({ request }) => {
    const history = useHistory()
    const orderQty = request.quantity
    const trades = Array.from(groupBy(request.order_contracts.map((order_contract) => {
        return order_contract.trade.filter(trade => !trade.is_deleted)
    }).flat(), t => t.created_at), ([date, trades]) => ({ date, trades }))
        .map((e, index) => {
            let trade = e.trades[0]
            return {
                trade: trade.id,
                time: new Date(trade.created_at).toLocaleString(undefined, {
                    hour: "numeric",
                    minute: "2-digit"
                }),
                tenor: [...new Set(request.order_contracts.filter(oc => oc.trade[0].id === trade.id).map((m) => {
                    return capitalize(m.maturity_shortened_string);
                }))].join(", ") ||
                    trade.maturities_to_display.map((m) => { return capitalize(m) }).join(', '),
                price: formatNumber(trade.value),
                qty: formatNumber(trade.quantity*request.order_contracts.length, false, /^-?\d+$/.test(trade.quantity) ? 0 : 2),
                broker: trade.broker.id,
                clearer: trade.clearer,
                trader: trade.trader.full_name

            }
        })

    const [isEditable, setEditable] = useState(0)

    const [{ data: brokers }, refetchBrokers] = useAxios({
        url: url("broker/"),
    });
    const [{ data: clearers }, refetchClearer] = useAxios({
        url: url("clearer/"),
    });
    const [localTrades, setLocalTrades] = useState(trades)
    const handleEditStatus = (state) => {
        setEditable(state)
    }

    const handleChange = (key, value) => {
        setLocalTrades(prev => {
            const temp = [...prev];
            const tempDataIndex = temp.findIndex(d => d.trade === isEditable);
            const rawValue = parseFloat(value.replace(/,/g, '')); // Remove any formatting

            if (key === "qty") {
                const newTradesData = temp.map(t => ({
                    ...t,
                    qty: t.trade === isEditable ? rawValue : parseFloat(t.qty.replace(/,/g, ''))
                }));
                let newTradeCount = newTradesData.reduce((acc, trade) => acc + trade.qty, 0);

                if (newTradeCount <= orderQty) {
                    temp[tempDataIndex][key] = rawValue;
                }
            } else {
                temp[tempDataIndex][key] = rawValue; // Use raw number for input value
            }
            return temp; // Return new state
        });
    };

    const saveChanges = () => {
        const findUpdatedTrade = localTrades.find(d => d.trade === isEditable)
        const { trade, price, qty: quantity, broker, clearer } = findUpdatedTrade
        const formattedPrice = price.toString().replace(/,/g, '');
        const formattedQty = quantity.toString().replace(/,/g, '');
        const body = {
            linked_order: request.id,
            price: parseFloat(formattedPrice),
            quantity: parseFloat(formattedQty),
            broker,
            clearer,
        }
        axios.put(url(`/trade/${trade}/`), body)
        setEditable(0)
        refetchBrokers()
        refetchClearer()
        history.push("/orders/");
    }

    const tableCSS = css({
        'thead > tr > th': {
            verticalAlign: 'top',
            fontSize: '14px',
            lineHeight: '22px',
            color: '#000000',
        },
        'tbody > tr > td': {
            fontSize: '14px',
            lineHeight: '22px',
            color: '#000000',
        },
    });




    return (
        <>
            <h4>Traded</h4>
            {localTrades.length ? <div style={{ position: "relative" }}>
                <div style={{ display: "inline-block", maxWidth: "100%" }}>
                    <Table
                        pagination={false}
                        dataSource={localTrades}
                        className={tableCSS}
                        scroll={{
                            x: true,
                        }}
                        size="small"
                        columns={columns(localTrades, brokers, clearers, isEditable, handleEditStatus, handleChange, saveChanges)}
                    />
                </div>
            </div> : <p>
                No trades entered.
            </p>}
        </>
    )
}

export default Trades


